import {
  Box,
  Card,
  Typography,
  Button,
  Stack,
  Drawer,
  Alert,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  MenuItem,
  IconButton
} from '@mui/material'
import { NppTypography } from 'bundles/ui/Components'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import * as React from 'react'
import * as dayjs from 'dayjs'
import { useState } from 'react'
import { Theme } from 'bundles/ui/Themes'
import { ThemeProvider } from '@mui/material/styles'
import {
  SummarizeAt,
  formatFilterOptions,
  periodFilterOptions,
  statusOptions,
  summarizeAtFilterOptions,
  ReportFormat,
  customBoxShadow
} from 'bundles/ui/SharedConstants'
import {
  OrganizationIcon,
  AccountIcon,
  ReportNameIcon,
  ReportDescriptionIcon,
  GeneratingStatusIcon,
  DownloadLinkIcon,
  RequestedAtIcon,
  ReportPeriodIcon,
  ProductTypeIcon,
  PartnerIcon,
  ReportFormatIcon
} from 'bundles/ui/SvgIcons'
import { useGetBsrRequestQuery, useLazyGetBsrRequestQuery } from 'bundles/features/bsrRequestApi'
import { useLazyGetBsrRequestItemQuery } from 'bundles/features/bsrRequestItemApi'
import { useEffect } from 'react'
import { DownloadLinks, StatusBadge, EmptyState, AlertMessage, CustomNoRowsOverlay } from './components'
import { columnsBsrRequestItem, columnsAccount, columnsOrganization, columns } from './gridDefinitions'
import { usePageAlert } from 'bundles/hooks'
import useSetDocumentTitle from '../sharedHooks/useSetDocumentTitle'
import useSetActiveMainMenu from '../sharedHooks/useSetActiveMainMenu'
import i18n from 'i18n'
import CloseIcon from '@mui/icons-material/Close'
import { useLocation } from 'react-router-dom'

const { t } = i18n

const paginationModel = { page: 0, pageSize: 10 }

type filter = {
  report_period?: number
  report_format?: string
  summary_level?: number
  status?: string
}

export default function BackupSummaryReport() {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false)
  const [rows, setRows] = useState([])
  const [selectedRowId, setSelectedRowId] = useState(null)
  const [filter, setFilter] = useState<filter>({})
  const [keyword, setKeyword] = useState('')
  const { data } = useGetBsrRequestQuery(undefined)
  const [lazyFetchBsr, { data: lazyData }] = useLazyGetBsrRequestQuery()
  const [lazyFetchBsrItem, { data: lazyBsrItemData }] = useLazyGetBsrRequestItemQuery()
  const { alert } = usePageAlert()
  const [isOpenFilterDropdown, setIsOpenFilterDropdown] = useState({
    requestedAt: false,
    summarizeAt: false,
    status: false,
    format: false
  })
  const location = useLocation()

  useSetDocumentTitle(t('reporting_dashboard.backup_summary_report.document_title'))
  useSetActiveMainMenu(location.pathname)

  useEffect(() => {
    setRows(data?.data)
  }, [data])

  useEffect(() => {
    setRows(lazyData?.data)
  }, [lazyData])

  useEffect(() => {
    lazyFetchBsr(filter)
  }, [filter])

  function onClick(id) {
    setOpenDrawer(true)
    setOpenDetailDrawer(false)
    setSelectedRowId(id)
    lazyFetchBsrItem({ bsr_id: id })
  }

  function onClickLink(id) {
    setOpenDetailDrawer(true)
    setSelectedRowId(id)
  }

  function onCloseDrawer() {
    setOpenDrawer(false)
    setOpenDetailDrawer(false)
    setSelectedRowId(null)
  }

  function onChangeKeywordText(e) {
    setKeyword(e.target.value)
  }

  function onSearchByKeyword(e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      setFilter((prevFilter) => ({ ...prevFilter, keyword: e.target.value }))
    }
  }

  function onChangeReportPeriodDropdown(e) {
    setIsOpenFilterDropdown((prev) => ({ ...prev, requestedAt: false }))
    if (e.target.value === 'all') {
      const { report_period: _, ...newFilter } = filter
      setFilter(newFilter)
      return
    }
    setFilter((prevFilter) => ({ ...prevFilter, report_period: e.target.value }))
  }

  function onChangeSummarizeAtDropdown(e) {
    setIsOpenFilterDropdown((prev) => ({ ...prev, summarizeAt: false }))
    if (e.target.value === 'all') {
      const { summary_level: _, ...newFilter } = filter
      setFilter(newFilter)
      return
    }
    setFilter((prevFilter) => ({ ...prevFilter, summary_level: e.target.value }))
  }

  function onChangeStatusDropdown(e) {
    setIsOpenFilterDropdown((prev) => ({ ...prev, status: false }))
    if (e.target.value === 'all') {
      const { status: _, ...newFilter } = filter
      setFilter(newFilter)
      return
    }
    setFilter((prevFilter) => ({ ...prevFilter, status: e.target.value }))
  }

  function onChangeReportFormatDropdown(e) {
    setIsOpenFilterDropdown((prev) => ({ ...prev, format: false }))
    if (e.target.value === 'all') {
      const { report_format: _, ...newFilter } = filter
      setFilter(newFilter)
      return
    }
    let format
    if (e.target.value === ReportFormat.pdf) {
      format = {
        pdf: true,
        csv: false
      }
    }
    if (e.target.value === ReportFormat.csv) {
      format = {
        pdf: false,
        csv: true
      }
    }
    setFilter((prevFilter) => ({ ...prevFilter, report_format: JSON.stringify(format) }))
  }

  function reportFormatText(reportFormat) {
    if (reportFormat === undefined) return
    if (reportFormat.pdf && reportFormat.csv) {
      return 'PDF & CSV'
    }
    if (reportFormat.pdf) {
      return 'PDF'
    }
    if (reportFormat.csv) {
      return 'CSV'
    }
  }

  const row = rows?.find((item) => item.id === selectedRowId)

  return (
    <ThemeProvider theme={Theme}>
      <Drawer open={openDrawer} onClose={onCloseDrawer} anchor="right">
        <Box
          sx={{
            position: 'fixed',
            transform: 'translateX(-53px)',
            p: 1
          }}>
          <IconButton
            onClick={onCloseDrawer}
            sx={{
              background: 'white',
              outline: 'none',
              '&:hover': {
                bgcolor: 'white'
              }
            }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: 420 }}>
          <Box sx={{ px: 2.5, py: 1.5 }}>
            <Typography variant="nppH2">
              {t('reporting_dashboard.backup_summary_report.download_reports')}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ px: 2.5, py: 1.5 }}>
            <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
              <ReportNameIcon />
              <Box>
                <Typography>{`ID: ${row?.id}-${row?.region_id}`}</Typography>
                <Typography>{row?.title}</Typography>
              </Box>
            </Stack>
            <Alert severity="info" icon={false} sx={{ mb: 3 }}>
              <Typography>
                <strong>Note: </strong>
                {t('reporting_dashboard.backup_summary_report.download_link_note')}
              </Typography>
            </Alert>
            <Accordion disableGutters={true} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                {t('reporting_dashboard.backup_summary_report.download_links')}
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <DataGrid
                    rows={lazyBsrItemData}
                    getRowId={(row) => row.id}
                    columns={columnsBsrRequestItem}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    columnHeaderHeight={1}
                    sx={{
                      border: 0,
                      '& .MuiTablePagination-toolbar p': {
                        mb: 'unset',
                        mt: 'unset'
                      },
                      '& .MuiDataGrid-cell': {
                        border: 'unset'
                      },
                      ['& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within']: {
                        outline: 'none'
                      },
                      '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'transparent'
                      },
                      '& .MuiDataGrid-container--top': {
                        visibility: 'hidden'
                      }
                    }}
                    disableRowSelectionOnClick
                    disableColumnResize
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Drawer>

      <Drawer open={openDetailDrawer} onClose={onCloseDrawer} anchor="right">
        <Box
          sx={{
            position: 'fixed',
            transform: 'translateX(-53px)',
            p: 1
          }}>
          <IconButton
            onClick={onCloseDrawer}
            sx={{
              background: 'white',
              outline: 'none',
              '&:hover': {
                bgcolor: 'white'
              }
            }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ width: 420 }}>
          <Box sx={{ px: 2.5, py: 1.5 }}>
            <Typography variant="nppH2">
              {t('reporting_dashboard.backup_summary_report.report_details')}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ px: 2.5, py: 1.5 }}>
            <Accordion disableGutters={true} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                {t('reporting_dashboard.backup_summary_report.report_info')}
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <ReportNameIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.report_name')}
                    </Typography>
                    <Typography variant="nppBodyText">{row?.title}</Typography>
                    <Typography variant="nppBodyText">{`${row?.id}-${row?.region_id}`}</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <ReportDescriptionIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.report_description')}
                    </Typography>
                    <Typography variant="nppBodyText">{row?.description || 'No description'}</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <GeneratingStatusIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.generating_status')}
                    </Typography>
                    <StatusBadge status={row?.status} />
                    {row?.end_time && (
                      <Typography variant="nppBodyText">{`Completed at: ${dayjs(row?.end_time).format(
                        'DD MMM YYYY HH:mm'
                      )}`}</Typography>
                    )}
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <DownloadLinkIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.download_link')}
                    </Typography>
                    <DownloadLinks
                      id={row?.id}
                      status={row?.status}
                      onClick={() => onClick(row?.id)}
                      partFinished={row?.part_finished}
                      partTotal={row?.part_total}
                      endTime={row?.end_time}
                      bsrItemExists={row?.bsr_items_exists}
                    />
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <ReportFormatIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.report_format')}
                    </Typography>
                    <Typography variant="nppParagraphSm">{reportFormatText(row?.report_format)}</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <RequestedAtIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.requested_at')}
                    </Typography>
                    <Typography variant="nppParagraphSm">
                      {dayjs(row?.created_at).format('DD MMM YYYY HH:mm')}
                    </Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <ReportPeriodIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.report_period')}
                    </Typography>
                    <Typography variant="nppBodyText">{`${dayjs(row?.period_start).format(
                      'DD MMM YYYY'
                    )} - ${dayjs(row?.period_end).format('DD MMM YYYY')}`}</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <AccountIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.requested_by')}
                    </Typography>
                    <Typography variant="nppParagraphSm">{row?.account_name}</Typography>
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion disableGutters={true} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                {t('reporting_dashboard.backup_summary_report.report_coverage')}
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <ProductTypeIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.product_type')}
                    </Typography>
                    <Typography variant="nppParagraphSm">Email & Collaboration</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <ProductTypeIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.summarize_at')}
                    </Typography>
                    <Typography
                      variant="nppParagraphSm"
                      sx={{ textTransform: 'capitalize' }}>{`${row?.summary_level} level`}</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <ProductTypeIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.workloads')}
                    </Typography>
                    <Typography variant="nppParagraphSm">
                      Account workload (Email, Drive, Contact, Calendar, Task, Private Chat for M365)
                    </Typography>
                  </Box>
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion disableGutters={true} defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                {t('reporting_dashboard.backup_summary_report.data_source')}
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <PartnerIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.partner')}
                    </Typography>
                    <Typography variant="nppBodyText">{row?.partner_name}</Typography>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                  <OrganizationIcon />
                  <Box>
                    <Typography variant="nppOverline">
                      {t('reporting_dashboard.backup_summary_report.organizations')}
                    </Typography>
                    <Typography variant="nppParagraphSm">{`${row?.selected_organizations?.length} Organization(s)`}</Typography>
                  </Box>
                </Stack>
                {row?.selected_accounts && (
                  <Stack direction="row" spacing={2} sx={{ py: 1.5, alignItems: 'center' }}>
                    <AccountIcon />
                    <Box>
                      <Typography variant="nppOverline">
                        {t('reporting_dashboard.backup_summary_report.accounts')}
                      </Typography>
                      <Typography variant="nppParagraphSm">{`${row?.selected_accounts?.length} Account(s)`}</Typography>
                    </Box>
                  </Stack>
                )}
              </AccordionDetails>
            </Accordion>
            {row?.summary_level === SummarizeAt.OrganizationLevel && (
              <Accordion disableGutters={true} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownOutlinedIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header">
                  {t('reporting_dashboard.backup_summary_report.organizations')}
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      border: '1px solid #C7CCD1',
                      borderRadius: 1
                    }}>
                    <DataGrid
                      rows={row?.selected_organizations}
                      getRowId={(row) => row.organization_id}
                      columns={columnsOrganization}
                      initialState={{ pagination: { paginationModel } }}
                      pageSizeOptions={[5, 10]}
                      sx={{
                        border: 0,
                        '& .MuiTablePagination-toolbar p': {
                          mb: 'unset',
                          mt: 'unset'
                        },
                        ['& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within']: {
                          outline: 'none'
                        }
                      }}
                      disableRowSelectionOnClick
                      disableColumnResize
                    />
                  </Box>
                  <Box
                    sx={{
                      height: 68
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            )}
            {row?.summary_level === SummarizeAt.AccountLevel && (
              <Accordion disableGutters={true} defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandCircleDownOutlinedIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header">
                  Account(s)
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    sx={{
                      border: '1px solid #C7CCD1',
                      borderRadius: 1
                    }}>
                    <DataGrid
                      rows={row?.selected_accounts}
                      getRowId={(row) => row.account_uid}
                      columns={columnsAccount}
                      initialState={{ pagination: { paginationModel } }}
                      pageSizeOptions={[5, 10]}
                      sx={{
                        border: 0,
                        '& .MuiTablePagination-toolbar p': {
                          mb: 'unset',
                          mt: 'unset'
                        },
                        ['& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within']: {
                          outline: 'none'
                        }
                      }}
                      disableRowSelectionOnClick
                      disableColumnResize
                    />
                  </Box>
                  <Box
                    sx={{
                      height: 68
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        </Box>
      </Drawer>

      <Box>
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'space-between',
            mb: 3
          }}>
          <Typography variant="nppH1">{t('reporting_dashboard.backup_summary_report.title')}</Typography>
          <Button
            variant="contained"
            sx={{
              textTransform: 'none',
              bgcolor: 'npp.blue.50'
            }}
            startIcon={<AddIcon />}
            href="/reports/backup_summary_report/new">
            {t('reporting_dashboard.backup_summary_report.new_report')}
          </Button>
        </Stack>

        {data?.bsrExists === false && <EmptyState />}

        {alert?.message && <AlertMessage message={alert?.message} />}

        {data?.bsrExists === true && (
          <Card
            sx={{
              boxShadow: customBoxShadow
            }}>
            <Box
              sx={{
                px: 2.5,
                py: 1.5
              }}>
              <NppTypography variant="nppH2">
                {t('reporting_dashboard.backup_summary_report.list_of_reports')}
              </NppTypography>
            </Box>
            <Divider></Divider>
            <Box sx={{ px: 2.5, py: 1.5 }}>
              <Stack spacing={2} direction="row">
                <TextField
                  sx={{
                    minWidth: 365
                  }}
                  onChange={onChangeKeywordText}
                  onKeyDown={onSearchByKeyword}
                  value={keyword}
                  placeholder={t('reporting_dashboard.backup_summary_report.search')}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }
                  }}
                />

                <TextField
                  select
                  defaultValue={'all'}
                  onChange={onChangeReportPeriodDropdown}
                  sx={{
                    '& .MuiInputBase-root': {
                      cursor: 'pointer'
                    }
                  }}
                  slotProps={{
                    select: {
                      open: isOpenFilterDropdown['requestedAt'],
                      onOpen: (e) => {
                        setIsOpenFilterDropdown((prev) => ({ ...prev, requestedAt: true }))
                      },
                      onClose: (e) => {
                        setIsOpenFilterDropdown((prev) => ({ ...prev, requestedAt: false }))
                      }
                    },
                    input: {
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            setIsOpenFilterDropdown((prev) => ({ ...prev, requestedAt: true }))
                          }}>
                          <Typography noWrap>
                            <strong>{`${t(
                              'reporting_dashboard.backup_summary_report.filter.requested_at'
                            )}:`}</strong>
                          </Typography>
                        </InputAdornment>
                      )
                    }
                  }}>
                  {periodFilterOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  defaultValue={'all'}
                  onChange={onChangeSummarizeAtDropdown}
                  sx={{
                    '& .MuiInputBase-root': {
                      cursor: 'pointer'
                    }
                  }}
                  slotProps={{
                    select: {
                      open: isOpenFilterDropdown['summarizeAt'],
                      onOpen: (e) => {
                        setIsOpenFilterDropdown((prev) => ({ ...prev, summarizeAt: true }))
                      },
                      onClose: (e) => {
                        setIsOpenFilterDropdown((prev) => ({ ...prev, summarizeAt: false }))
                      }
                    },
                    input: {
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            setIsOpenFilterDropdown((prev) => ({ ...prev, summarizeAt: true }))
                          }}>
                          <Typography noWrap>
                            <strong>{`${t(
                              'reporting_dashboard.backup_summary_report.filter.summarize_at'
                            )}:`}</strong>
                          </Typography>
                        </InputAdornment>
                      )
                    }
                  }}>
                  {summarizeAtFilterOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  defaultValue={'all'}
                  onChange={onChangeStatusDropdown}
                  sx={{
                    '& .MuiInputBase-root': {
                      cursor: 'pointer'
                    }
                  }}
                  slotProps={{
                    select: {
                      open: isOpenFilterDropdown['status'],
                      onOpen: (e) => {
                        setIsOpenFilterDropdown((prev) => ({ ...prev, status: true }))
                      },
                      onClose: (e) => {
                        setIsOpenFilterDropdown((prev) => ({ ...prev, status: false }))
                      }
                    },
                    input: {
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            setIsOpenFilterDropdown((prev) => ({ ...prev, status: true }))
                          }}>
                          <Typography noWrap>
                            <strong>{`${t(
                              'reporting_dashboard.backup_summary_report.filter.status'
                            )}:`}</strong>
                          </Typography>
                        </InputAdornment>
                      )
                    }
                  }}>
                  {statusOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  defaultValue={'all'}
                  onChange={onChangeReportFormatDropdown}
                  sx={{
                    '& .MuiInputBase-root': {
                      cursor: 'pointer'
                    }
                  }}
                  slotProps={{
                    select: {
                      open: isOpenFilterDropdown['format'],
                      onOpen: (e) => {
                        setIsOpenFilterDropdown((prev) => ({ ...prev, format: true }))
                      },
                      onClose: (e) => {
                        setIsOpenFilterDropdown((prev) => ({ ...prev, format: false }))
                      }
                    },
                    input: {
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            setIsOpenFilterDropdown((prev) => ({ ...prev, format: true }))
                          }}>
                          <Typography noWrap>
                            <strong>{`${t(
                              'reporting_dashboard.backup_summary_report.filter.format'
                            )}:`}</strong>
                          </Typography>
                        </InputAdornment>
                      )
                    }
                  }}>
                  {formatFilterOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </Box>
            <Divider></Divider>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 461
              }}>
              <DataGrid
                rows={rows}
                columns={columns(onClick, onClickLink)}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10]}
                disableColumnResize
                sx={{
                  border: 0,
                  '& .MuiDataGrid-columnHeader': {
                    fontSize: 14
                  },
                  '& .MuiTablePagination-toolbar p': {
                    mb: 'unset',
                    mt: 'unset'
                  },
                  ['& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within']: {
                    outline: 'none'
                  }
                }}
                rowHeight={88}
                disableRowSelectionOnClick
                disableColumnMenu={true}
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay
                }}
              />
            </Box>
          </Card>
        )}
      </Box>
    </ThemeProvider>
  )
}
