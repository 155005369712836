import * as React from 'react'
import * as Routes from 'routes'

export default function useSetActiveMainMenu(location) {
  React.useEffect(() => {
    if (location !== Routes.reports_backup_summary_path() && location !== Routes.create_reports_backup_summary_path()) return

    const mainMenuNavigation = document.querySelector('#main-menu-navigation #bsr')
    if (mainMenuNavigation) {
      mainMenuNavigation.classList.add('open', 'active')
    }

    return () => {
      if (mainMenuNavigation) {
        mainMenuNavigation.classList.remove('open', 'active')
      }
    }
  }, [])
}
