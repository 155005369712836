import * as React from 'react'
import { GeneratingStatus } from 'bundles/ui/SharedConstants'
import {
  Button,
  Typography,
  Stack,
  Chip,
  IconButton,
  Card,
  Box,
  Collapse,
  Alert,
  AlertTitle
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CheckIcon from '@mui/icons-material/Check'
import CachedIcon from '@mui/icons-material/Cached'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import ScheduleIcon from '@mui/icons-material/Schedule'
import { LoadingIcon } from 'bundles/ui/SvgIcons'
import { useLazyGenerateBsrRequestItemDownloadLinkQuery } from 'bundles/features/bsrRequestItemApi'
import InfoIcon from '@mui/icons-material/Info'
import image1 from '../empty-state.png'
import { isEndTimeExpired } from 'bundles/ui/Helpers'
import i18n from 'i18n'

const { t } = i18n

type SmallDownloadButtonProps = {
  [key: string]: any
}

export function DownloadLinks(props) {
  const isDownloadLinkExists = props.partFinished >= 1 || props.bsrItemExists === true
  const isDownloadLinkNotExist = props.partFinished === 0 || props.bsrItemExists === false

  function statusIcon() {
    if (!props.isInTable) {
      return
    }
    if (props.partFinished === props.partTotal) {
      return <CheckCircleOutlineIcon fontSize="small" />
    } else {
      return <LoadingIcon fontSize="small" />
    }
  }

  if (isDownloadLinkExists && isEndTimeExpired(props.endTime)) {
    return (
      <Stack justifyContent={'center'} sx={{ height: '100%' }}>
        <Button
          variant="contained"
          sx={{ textTransform: 'none', mb: 0.5 }}
          startIcon={<HourglassBottomIcon />}
          disabled>
          {t('reporting_dashboard.backup_summary_report.link_expired')}
        </Button>
        <Stack direction={'row'} spacing={0.5}>
          <CheckCircleOutlineIcon fontSize="small" />
          <Typography variant="nppOverline">{`${props.partFinished}/${props.partTotal} ${t(
            'reporting_dashboard.backup_summary_report.parts'
          )}`}</Typography>
        </Stack>
      </Stack>
    )
  }

  if (isDownloadLinkExists) {
    return (
      <Stack justifyContent={'center'} sx={{ height: '100%' }} alignItems={props.isInTable ? 'center' : ''}>
        <Button
          variant="contained"
          sx={{
            textTransform: 'none',
            bgcolor: 'npp.blue.50',
            mb: 0.5
          }}
          startIcon={<DownloadIcon />}
          onClick={props.onClick}>
          {t('reporting_dashboard.backup_summary_report.download')}
        </Button>
        <Stack direction={'row'} spacing={0.5}>
          {statusIcon()}
          <Typography variant="nppOverline">{`${props.partFinished}/${props.partTotal} ${t(
            'reporting_dashboard.backup_summary_report.parts'
          )}`}</Typography>
        </Stack>
      </Stack>
    )
  }

  if (isDownloadLinkNotExist) {
    return (
      <Stack justifyContent={'center'} sx={{ height: '100%' }} alignItems={props.isInTable ? 'center' : ''}>
        <Typography>-</Typography>
      </Stack>
    )
  }
}

export function StatusBadge(props) {
  const isError = props.status === GeneratingStatus.ErrorAfterRetrying
  const isGenerating =
    props.status === GeneratingStatus.InProgress || props.status === GeneratingStatus.ErrorBeforeRetrying
  const isCompleted =
    props.status === GeneratingStatus.Completed || props.status === GeneratingStatus.CompletedEmptyData
  const isRequesting = props.status === GeneratingStatus.Requested

  function badgeBackgroundColor() {
    if (isGenerating) {
      return 'npp.blue.20'
    }
    if (isCompleted) {
      return 'npp.green.20'
    }
    if (isRequesting) {
      return 'npp.grey.100'
    }
    if (isError) {
      return '#FCE3E6'
    }
  }

  function badgeIcon() {
    if (isGenerating) {
      return <CachedIcon sx={{ fontSize: '1rem' }} />
    }
    if (isCompleted) {
      return <CheckIcon sx={{ fontSize: '1rem' }} />
    }
    if (isRequesting) {
      return <ScheduleIcon sx={{ fontSize: '1rem' }} />
    }
    if (isError) {
      return <ErrorOutlineIcon sx={{ fontSize: '1rem' }} />
    }
  }

  function badgeText() {
    if (isGenerating) {
      return t('reporting_dashboard.backup_summary_report.status_badge.generating')
    }
    if (isCompleted) {
      return t('reporting_dashboard.backup_summary_report.status_badge.completed')
    }
    if (isRequesting) {
      return t('reporting_dashboard.backup_summary_report.status_badge.queued')
    }
    if (isError) {
      return t('reporting_dashboard.backup_summary_report.status_badge.failed')
    }
  }

  function border() {
    if (isGenerating) {
      return '1px solid #8CC4F8'
    }
    if (isCompleted) {
      return '1px solid #A8DCB4'
    }
    if (isRequesting) {
      return '1px solid #ADB5BD'
    }
    if (isError) {
      return '1px solid #F4A9B3'
    }
  }

  return (
    <Chip
      icon={badgeIcon()}
      label={badgeText()}
      sx={{
        borderRadius: 1,
        bgcolor: badgeBackgroundColor(),
        border: border()
      }}
    />
  )
}

export function SmallDownloadButton(props: SmallDownloadButtonProps) {
  const [generateDownloadLink, { data, error, isSuccess }] = useLazyGenerateBsrRequestItemDownloadLinkQuery()
  const isDownloadLinkDisabled = isEndTimeExpired(props.end_time) || props.status !== GeneratingStatus.Completed

  React.useEffect(() => {
    if (isSuccess) {
      window.location.href = data?.url
    }
  }, [isSuccess, data])

  function icon() {
    if (isEndTimeExpired(props.end_time)) {
      return <HourglassBottomIcon />
    }
    if (props.status !== GeneratingStatus.Completed) {
      return <LoadingIcon />
    }
    return <DownloadIcon />
  }

  return (
    <IconButton
      disabled={isDownloadLinkDisabled}
      sx={{
        color: '#FFFFFF',
        bgcolor: 'npp.blue.50',
        borderRadius: 1,
        width: 52,
        '&:hover': {
          bgcolor: 'npp.blue.50'
        },
        '&.Mui-disabled': {
          bgcolor: 'npp.primary.1'
        }
      }}
      onClick={(e) => {
        generateDownloadLink({ file_path: props.file_path })
      }}>
      {icon()}
    </IconButton>
  )
}

export function EmptyState() {
  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: 560,
        justifyContent: 'center'
      }}>
      <Box component={'img'} src={image1} sx={{ width: 160 }} />
      <Typography variant="nppH3">
        {t('reporting_dashboard.backup_summary_report.empty_state.heading')}
      </Typography>
      <Typography variant="nppBodyText">
        {t('reporting_dashboard.backup_summary_report.empty_state.subheading')}
      </Typography>
    </Card>
  )
}

export function CustomNoRowsOverlay(){
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>
      <Box component={'img'} src={image1} sx={{ width: 160 }} />
      <Typography variant="nppH3">
        {t('reporting_dashboard.backup_summary_report.not_found.heading')}
      </Typography>
      <Typography variant="nppBodyText">
        {t('reporting_dashboard.backup_summary_report.not_found.subheading')}
      </Typography>
    </Box>
  )
}

export function AlertMessage(props) {
  const [isShow, setIsShow] = React.useState(true)

  return (
    <Collapse in={isShow}>
      <Alert
        icon={<InfoIcon />}
        severity="success"
        sx={{ mb: 1 }}
        onClose={() => {
          setIsShow(false)
        }}>
        <AlertTitle>{t('reporting_dashboard.new_backup_summary_report.success_heading')}</AlertTitle>
        <Typography>{props.message}</Typography>
      </Alert>
    </Collapse>
  )
}
