import * as React from 'react'
import {
  TextField,
  InputLabel,
  Box,
  Card,
  FormHelperText,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Stack,
  Button,
  Autocomplete,
  Typography,
  FormControl,
  Link,
  Drawer,
  Divider,
  Alert,
  Collapse,
  AlertTitle
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import {
  useGetListPartnerDropdownQuery,
  useSetSelectedPartnerMutation
} from 'bundles/features/listPartnerDropdown/services/listPartnerApi'
import {
  useLazyGetListOrganizationDropdownQuery,
  useSetSelectedOrganizationMutation
} from 'bundles/features/listOrganizationDropdown'
import { useLazyGetAccountQuery } from 'bundles/features/accountApi'
import BaseSetting from 'bundles/config/BaseSetting'
import { useEffect, useState } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import InfoIcon from '@mui/icons-material/Info'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Theme } from 'bundles/ui/Themes'
import {
  SummarizeAt,
  periodOptions,
  productTypeOptions,
  summarizeAtOptions,
  workloadOptions,
  customBoxShadow
} from 'bundles/ui/SharedConstants'
import { useCreateBsrRequestMutation } from 'bundles/features/bsrRequestApi'
import { ReportPeriod } from 'bundles/ui/SharedConstants'
import { convertReportPeriodToDays } from 'bundles/ui/Helpers'
import sampleImage from './backup-sample-organization.png'
import sampleImage2 from './backup-sample-account-1.png'
import sampleImage3 from './backup-sample-account-2.png'
import { SerializedError } from '@reduxjs/toolkit'
import { useNavigate, useLocation } from 'react-router-dom'
import { usePageAlert } from 'bundles/hooks'
import * as dayjs from 'dayjs'
import useSetDocumentTitle from '../sharedHooks/useSetDocumentTitle'
import useSetActiveMainMenu from '../sharedHooks/useSetActiveMainMenu'
import { SampleImage, ErrorMessage, SummarizedFormHelperText, PdfLabel, CsvLabel } from './components'
import i18n from 'i18n'

const { t } = i18n

interface CustomError extends SerializedError {
  data?: any
}

export default function CreateBackupSummaryReport() {
  const { data: dataPartner, isFetching: isFetchingPartner } = useGetListPartnerDropdownQuery()
  const [fetchOrganization, { data: dataOrganization, isFetching: isFetchingOrganization }] =
    useLazyGetListOrganizationDropdownQuery()
  const [fetchAccount, { data: dataAccount, isFetching: isFetchingAccount }] = useLazyGetAccountQuery()
  const [setSelectedPartner, { isSuccess: isSuccessSelectingPartner }] = useSetSelectedPartnerMutation()
  const [setSelectedOrganization, { isSuccess: isSuccessSelectingOrganization }] =
    useSetSelectedOrganizationMutation()
  const [
    createBsrRequest,
    { isLoading: isLoadingCreateBsr, error: errorCreateBsr, isSuccess: isSuccessCreateBsr }
  ] = useCreateBsrRequestMutation()
  const [partners, setPartners] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [accounts, setAccounts] = useState([])
  const [selectedSummarizeAt, setSelectedSummarizedAt] = useState(SummarizeAt.OrganizationLevel)
  const [reportName, setReportName] = useState('')
  const [reportDescription, setReportDescription] = useState('')
  const [reportPeriod, setReportPeriod] = useState(ReportPeriod.ThisMonth)
  const [selectedDropdownPartner, setSelectedDropdownPartner] = useState(null)
  const [selectedDropdownOrganization, setSelectedDropdownOrganization] = useState([])
  const [selectedDropdownSingleOrganization, setSelectedDropdownSingleOrganization] = useState(null)
  const [selectedDropdownAccount, setSelectedDropdownAccount] = useState([])
  const [checked, setChecked] = useState({
    pdf: false,
    csv: false
  })
  const [openOrganizationSampleDrawer, setOpenOrganizationSampleDrawer] = useState(false)
  const [openAccountSampleDrawer, setOpenAccountSampleDrawer] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { updateSuccessAlert } = usePageAlert()
  useSetDocumentTitle(t('reporting_dashboard.new_backup_summary_report.document_title'))
  useSetActiveMainMenu(location.pathname)

  const customErrorCreateBsr = errorCreateBsr as CustomError

  useEffect(() => {
    if (dataPartner === undefined) return

    const partnerList = dataPartner.map((item) => {
      return {
        value: item.id,
        label: item.organization_name
      }
    })
    setPartners(partnerList)
  }, [isFetchingPartner])

  useEffect(() => {
    if (dataOrganization === undefined) return

    const organizationList = dataOrganization.map((item) => {
      return {
        value: item.id,
        label: item.organization_name
      }
    })

    if (organizationList.length === 0) {
      setOrganizations(organizationList)
    } else {
      const allOption = [
        {
          value: '-1-1',
          label: 'all'
        }
      ]
      setOrganizations([...allOption, ...organizationList])
    }
  }, [isFetchingOrganization])

  useEffect(() => {
    if (dataAccount === undefined) return

    const accountList = dataAccount.map((item) => {
      return {
        value: item.uid,
        label: item.product_name
      }
    })
    if (accountList.length === 0) {
      setAccounts(accountList)
    } else {
      const allOption = [
        {
          value: '-1-1',
          label: 'all'
        }
      ]
      setAccounts([...allOption, ...accountList])
    }
  }, [isFetchingAccount])

  useEffect(() => {
    if (isSuccessSelectingPartner) {
      fetchOrganization({ page_size: 10000 })
    }
  }, [isSuccessSelectingPartner])

  useEffect(() => {
    if (isSuccessSelectingOrganization) {
      fetchAccount({
        page_size: 10000,
        page_action: null,
        filters: JSON.stringify([{ field: 'status', type: '=', value: 'active' }])
      })
    }
  }, [isSuccessSelectingOrganization])

  useEffect(() => {
    if (isSuccessCreateBsr) {
      updateSuccessAlert({
        message: t('reporting_dashboard.new_backup_summary_report.success_message')
      })
      navigate('/reports/backup_summary_report')
    }
  }, [isSuccessCreateBsr])

  useEffect(() => {
    if (errorCreateBsr) {
      setOpenAlert(true)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }, [errorCreateBsr])

  function onChangePartnerDropdown(e, v, r, d) {
    const payload = {
      csrfToken: BaseSetting.csrfToken,
      partners: [v?.value],
      path: new URL(window.location.href).pathname
    }
    if (r === 'selectOption') {
      setSelectedPartner(payload)
    }
    if (r === 'clear') {
      setOrganizations([])
      setAccounts([])
    }
    setSelectedDropdownPartner(v)
    setSelectedDropdownOrganization([])
    setSelectedDropdownSingleOrganization(null)
    setSelectedDropdownAccount([])
  }

  function onChangeOrganizationDropdown(e, v, r, d) {
    if (r === 'selectOption' && d.option?.label === 'all') {
      setSelectedDropdownOrganization(v.filter((item) => item.label === 'all'))
      // setSelectedDropdownOrganization(organizations)
      return
    }
    if (
      r === 'selectOption' &&
      selectedDropdownOrganization.some((item) => item.value === '-1-1' && item.label === 'all')
    ) {
      return
    }
    setSelectedDropdownOrganization(v)
  }

  function onChangeAccountDropdown(e, v, r, d) {
    setSelectedDropdownAccount(v)
  }

  function onChangeSingleOrganizationDropdown(e, v, r, d) {
    const payload = {
      csrfToken: BaseSetting.csrfToken,
      organizations: [v?.value],
      path: new URL(window.location.href).pathname
    }
    if (r === 'selectOption') {
      setSelectedOrganization(payload)
    }
    if (r === 'clear') {
      setAccounts([])
    }
    setSelectedDropdownSingleOrganization(v)
    setSelectedDropdownAccount([])
  }

  function onChangeReportName(e) {
    setReportName(e.target.value)
  }

  function onChangeReportDescription(e) {
    setReportDescription(e.target.value)
  }

  function onChangeSummarizedAtDropdown(e) {
    setSelectedSummarizedAt(e.target.value)
    setSelectedDropdownPartner(null)
    setSelectedDropdownOrganization([])
    setSelectedDropdownSingleOrganization(null)
    setSelectedDropdownAccount([])
  }

  function onChangeReportPeriod(e) {
    setReportPeriod(e.target.value)
  }

  function onChangeReportFormat(e) {
    setChecked({ ...checked, [e.target.name]: e.target.checked })
  }

  function onClickOrganizationSample(e) {
    setOpenOrganizationSampleDrawer(true)
  }

  function onClickAccountSample(e) {
    setOpenAccountSampleDrawer(true)
  }

  function onCloseDrawer() {
    setOpenOrganizationSampleDrawer(false)
    setOpenAccountSampleDrawer(false)
  }

  function startEndText() {
    const { start, end } = convertReportPeriodToDays(reportPeriod)
    return `${dayjs(start).format('DD MMM YYYY')} - ${dayjs(end).format('DD MMM YYYY')}`
  }

  function reportFormatBorderColor(error, checked) {
    if (error) {
      return '#d32f2f'
    }
    if (checked) {
      return 'npp.blue.50'
    }
    return 'npp.primary.3'
  }

  async function onClickCreateButton(e) {
    const { start, end } = convertReportPeriodToDays(reportPeriod)
    let acc, org
    if (selectedSummarizeAt === SummarizeAt.OrganizationLevel) {
      org = selectedDropdownOrganization.map((item) => ({
        organization_id: item.value,
        organization_name: item.label
      }))
    }
    if (selectedSummarizeAt === SummarizeAt.AccountLevel) {
      org = selectedDropdownSingleOrganization
        ? [
            {
              organization_id: selectedDropdownSingleOrganization.value,
              organization_name: selectedDropdownSingleOrganization.label
            }
          ]
        : []

      acc = selectedDropdownAccount.map((item) => ({
        account_uid: item.value,
        account_email: item.label
      }))
    }
    const payload: { csrfToken: any; body: any } = {
      csrfToken: BaseSetting.csrfToken,
      body: {
        title: reportName,
        description: reportDescription,
        period_start: start,
        period_end: end,
        summary_level: selectedSummarizeAt,
        report_format: {
          pdf: checked.pdf,
          csv: checked.csv
        },
        workloads: 'account',
        product_type: 'email',
        selected_partners: [selectedDropdownPartner?.value],
        selected_organizations: org,
        report_period: reportPeriod
      }
    }
    if (selectedSummarizeAt === SummarizeAt.AccountLevel) {
      payload.body.selected_accounts = acc
    }
    try {
      await createBsrRequest(payload).unwrap()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <ThemeProvider theme={Theme}>
      <Drawer open={openOrganizationSampleDrawer} onClose={onCloseDrawer} anchor="right">
        <Box sx={{ width: 640 }}>
          <Box sx={{ px: 2.5, py: 1.5 }}>
            <Typography variant="nppH3">
              {t('reporting_dashboard.new_backup_summary_report.organization_level_summary')}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ bgcolor: 'npp.primary.2', px: 2.5, py: 2 }}>
            <SampleImage imageSrc={sampleImage} />
          </Box>
        </Box>
      </Drawer>
      <Drawer open={openAccountSampleDrawer} onClose={onCloseDrawer} anchor="right">
        <Box sx={{ width: 780 }}>
          <Box sx={{ px: 2.5, py: 1.5 }}>
            <Typography variant="nppH3">
              {t('reporting_dashboard.new_backup_summary_report.account_level_summary')}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ bgcolor: 'npp.primary.2', px: 2.5, py: 2 }}>
            <SampleImage imageSrc={sampleImage2} />
            <SampleImage imageSrc={sampleImage3} />
          </Box>
        </Box>
      </Drawer>
      <Box
        sx={{
          width: 738,
          m: 'auto'
        }}>
        <Stack direction={'row'}>
          <ChevronLeftIcon sx={{ color: 'npp.blue.50' }} />
          <Link href="/reports/backup_summary_report" underline="none">
            <Typography variant="nppBodyText" sx={{ color: 'npp.blue.50' }}>
              {t('reporting_dashboard.new_backup_summary_report.back_link')}
            </Typography>
          </Link>
        </Stack>
        <Typography variant="nppH1" sx={{ mb: 1.5 }}>
          {t('reporting_dashboard.new_backup_summary_report.title')}
        </Typography>

        {customErrorCreateBsr && (
          <Collapse in={openAlert}>
            <Alert
              icon={<InfoIcon />}
              severity="error"
              onClose={() => {
                setOpenAlert(false)
              }}
              sx={{ mb: 2 }}>
              <AlertTitle>{t('reporting_dashboard.new_backup_summary_report.error_heading')}</AlertTitle>
              <Typography variant="nppParagraphSm">
                {t('reporting_dashboard.new_backup_summary_report.error_message')}
              </Typography>
            </Alert>
          </Collapse>
        )}

        <Card
          sx={{
            px: 4,
            py: 3,
            mb: 1,
            boxShadow: customBoxShadow
          }}>
          <Typography variant="nppH2" sx={{ mb: 1.5 }}>
            {t('reporting_dashboard.new_backup_summary_report.report_info')}
          </Typography>
          <InputLabel htmlFor="report-name-required">
            <Typography variant="nppBodyTitle">
              {t('reporting_dashboard.new_backup_summary_report.report_name')}
            </Typography>
          </InputLabel>
          <TextField
            error={customErrorCreateBsr?.data.title ? true : false}
            helperText={<ErrorMessage errorText={customErrorCreateBsr?.data.title?.[0]} />}
            slotProps={{ formHelperText: { sx: { mx: 0 }, component: 'div' } }}
            fullWidth
            id="report-name-required"
            placeholder={t('reporting_dashboard.new_backup_summary_report.report_name')}
            variant="outlined"
            sx={{
              mb: 3
            }}
            required
            onChange={onChangeReportName}
            value={reportName}
          />

          <InputLabel htmlFor="report-description-optional">
            <Typography variant="nppBodyTitle">
              {t('reporting_dashboard.new_backup_summary_report.report_description')}
            </Typography>
          </InputLabel>
          <TextField
            error={customErrorCreateBsr?.data.description ? true : false}
            helperText={<ErrorMessage errorText={customErrorCreateBsr?.data.description} />}
            slotProps={{ formHelperText: { sx: { mx: 0 }, component: 'div' } }}
            fullWidth
            id="report-description-optional"
            multiline
            placeholder={t('reporting_dashboard.new_backup_summary_report.report_description_placeholder')}
            minRows={2}
            onChange={onChangeReportDescription}
            value={reportDescription}
          />
        </Card>

        <Card
          sx={{
            px: 4,
            py: 3,
            mb: 1,
            boxShadow: customBoxShadow
          }}>
          <Typography variant="nppH2" sx={{ mb: 1.5 }}>
            {t('reporting_dashboard.new_backup_summary_report.report_period')}
          </Typography>
          <InputLabel htmlFor="report-period-required">
            <Typography variant="nppBodyTitle">
              {t('reporting_dashboard.new_backup_summary_report.report_period')}
            </Typography>
          </InputLabel>
          <TextField
            fullWidth
            id="report-period-required"
            variant="outlined"
            select
            value={reportPeriod}
            onChange={onChangeReportPeriod}>
            {periodOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText id="component-helper-text" sx={{ mb: 3 }}>
            <Typography variant="nppOverline">{startEndText()}</Typography>
          </FormHelperText>
        </Card>

        <Card
          sx={{
            px: 4,
            py: 3,
            mb: 1,
            boxShadow: customBoxShadow
          }}>
          <Typography variant="nppH2" sx={{ mb: 1.5 }}>
            {t('reporting_dashboard.new_backup_summary_report.report_coverage')}
          </Typography>
          <InputLabel htmlFor="product-type-required">
            <Typography variant="nppBodyTitle">
              {t('reporting_dashboard.new_backup_summary_report.product_type')}
            </Typography>
          </InputLabel>
          <TextField
            fullWidth
            id="product-type-required"
            variant="outlined"
            select
            disabled
            defaultValue={1}
            sx={{
              '& .Mui-disabled': {
                bgcolor: 'npp.primary.2'
              }
            }}>
            {productTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText id="component-helper-text" sx={{ mb: 3 }}>
            <Typography variant="nppOverline" sx={{ display: 'inline', fontWeight: 600 }}>
              Note:{' '}
            </Typography>
            <Typography variant="nppOverline" sx={{ display: 'inline' }}>
              More product will be available soon.
            </Typography>
          </FormHelperText>

          <InputLabel htmlFor="summarize-at-required">
            <Typography variant="nppBodyTitle">
              {t('reporting_dashboard.new_backup_summary_report.summarize_at')}
            </Typography>
          </InputLabel>
          <TextField
            fullWidth
            id="summarize-at-required"
            variant="outlined"
            select
            value={selectedSummarizeAt}
            onChange={onChangeSummarizedAtDropdown}>
            {summarizeAtOptions.map((option, i) => (
              <MenuItem key={i} value={option.value} disabled={option.value === SummarizeAt.AccountLevel}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText id="component-helper-text" sx={{ mb: 3 }}>
            <SummarizedFormHelperText
              selectedSummarizeAt={selectedSummarizeAt}
              onClickOrganizationSample={onClickOrganizationSample}
              onClickAccountSample={onClickAccountSample}
            />
          </FormHelperText>

          <InputLabel htmlFor="workloads-required">
            <Typography variant="nppBodyTitle">
              {t('reporting_dashboard.new_backup_summary_report.workloads')}
            </Typography>
          </InputLabel>
          <TextField
            fullWidth
            id="workloads-required"
            variant="outlined"
            select
            disabled
            defaultValue={1}
            sx={{
              '& .Mui-disabled': {
                bgcolor: 'npp.primary.2'
              }
            }}>
            {workloadOptions.map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <FormHelperText id="component-helper-text">
            <Typography variant="nppOverline" sx={{ display: 'inline', fontWeight: 600 }}>
              Note:{' '}
            </Typography>
            <Typography variant="nppOverline" sx={{ display: 'inline' }}>
              More workloads will be available soon.
            </Typography>
          </FormHelperText>
        </Card>

        <Card
          sx={{
            px: 4,
            py: 3,
            mb: 1,
            boxShadow: customBoxShadow
          }}>
          <Typography variant="nppH2" sx={{ mb: 1.5 }}>
            {t('reporting_dashboard.new_backup_summary_report.data_source')}
          </Typography>

          <InputLabel htmlFor="outlined-required">
            <Typography variant="nppBodyTitle">
              {t('reporting_dashboard.new_backup_summary_report.partner')}
            </Typography>
          </InputLabel>
          <Autocomplete
            fullWidth
            disablePortal
            options={partners}
            value={selectedDropdownPartner}
            getOptionKey={(option) => option.value}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder={t('reporting_dashboard.new_backup_summary_report.partner_placeholder')}
                error={customErrorCreateBsr?.data.selected_partners ? true : false}
                helperText={<ErrorMessage errorText={customErrorCreateBsr?.data.selected_partners?.[0]} />}
                slotProps={{
                  formHelperText: {
                    sx: { mx: 0 },
                    component: 'div'
                  }
                }}
              />
            )}
            onChange={onChangePartnerDropdown}
            sx={{
              mb: 2
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.value}>
                {option.label}
              </li>
            )}
          />

          <InputLabel htmlFor="outlined-required">
            <Typography variant="nppBodyTitle">
              {t('reporting_dashboard.new_backup_summary_report.organizations')}
            </Typography>
          </InputLabel>
          {selectedSummarizeAt === SummarizeAt.OrganizationLevel && (
            <>
              <Autocomplete
                getOptionLabel={(option) => {
                  return option.label === 'all' ? 'All organizations' : option.label
                }}
                disableCloseOnSelect
                multiple
                disabled={isFetchingOrganization || organizations.length === 0}
                options={organizations}
                value={selectedDropdownOrganization}
                getOptionKey={(option) => option.value}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('reporting_dashboard.new_backup_summary_report.organizations_placeholder')}
                    error={customErrorCreateBsr?.data.selected_organizations ? true : false}
                    helperText={
                      <ErrorMessage errorText={customErrorCreateBsr?.data.selected_organizations?.[0]} />
                    }
                    slotProps={{ formHelperText: { sx: { mx: 0 }, component: 'div' } }}
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        checked={selected}
                      />
                      {option.label === 'all' ? 'All organizations' : option.label}
                    </li>
                  )
                }}
                onChange={onChangeOrganizationDropdown}
                sx={{
                  mb: 2,
                  '& .Mui-disabled': {
                    bgcolor: 'npp.primary.2'
                  },
                  '& .MuiFormHelperText-root': {
                    bgcolor: 'unset'
                  }
                }}
              />
              <Alert icon={false} severity="info">
                <Typography variant="nppBodyText" sx={{ display: 'inline', fontWeight: 600 }}>
                  Note:{' '}
                </Typography>
                <Typography variant="nppBodyText" sx={{ display: 'inline' }}>
                  please note that for large data request will be split into multiple parts.
                </Typography>
              </Alert>
            </>
          )}
          {selectedSummarizeAt === SummarizeAt.AccountLevel && (
            <>
              <Autocomplete
                fullWidth
                disabled={isFetchingOrganization || organizations.length === 0}
                disablePortal
                value={selectedDropdownSingleOrganization}
                getOptionKey={(option) => option.value}
                options={organizations}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t('reporting_dashboard.new_backup_summary_report.organizations_placeholder')}
                    error={customErrorCreateBsr?.data.selected_organizations ? true : false}
                    helperText={
                      <ErrorMessage errorText={customErrorCreateBsr?.data.selected_organizations?.[0]} />
                    }
                    slotProps={{ formHelperText: { sx: { mx: 0 }, component: 'div' } }}
                  />
                )}
                onChange={onChangeSingleOrganizationDropdown}
                sx={{
                  '& .Mui-disabled': {
                    bgcolor: 'npp.primary.2'
                  },
                  '& .MuiFormHelperText-root': {
                    bgcolor: 'unset'
                  },
                  mb: 3
                }}
              />
              <InputLabel htmlFor="outlined-required">
                <Typography variant="nppBodyTitle">
                  {t('reporting_dashboard.new_backup_summary_report.accounts')}
                </Typography>
              </InputLabel>
              <Autocomplete
                disableCloseOnSelect
                multiple
                disabled={isFetchingAccount || accounts.length === 0}
                options={accounts}
                getOptionKey={(option) => option.value}
                value={selectedDropdownAccount}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('reporting_dashboard.new_backup_summary_report.accounts_placeholder')}
                    error={customErrorCreateBsr?.data.selected_accounts ? true : false}
                    helperText={<ErrorMessage errorText={customErrorCreateBsr?.data.selected_accounts?.[0]} />}
                    slotProps={{ formHelperText: { sx: { mx: 0 }, component: 'div' } }}
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon />}
                        checkedIcon={<CheckBoxIcon />}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )
                }}
                onChange={onChangeAccountDropdown}
                sx={{
                  '& .Mui-disabled': {
                    bgcolor: 'npp.primary.2'
                  },
                  '& .MuiFormHelperText-root': {
                    bgcolor: 'unset'
                  }
                }}
              />
            </>
          )}
        </Card>

        <Card
          sx={{
            px: 4,
            py: 3,
            mb: 1,
            boxShadow: customBoxShadow
          }}>
          <Typography variant="nppH2" sx={{ mb: 1.5 }}>
            {t('reporting_dashboard.new_backup_summary_report.report_format')}
          </Typography>
          <FormHelperText id="component-helper-text">
            <Typography variant="nppBodyTitle" sx={{ mb: 0.5 }}>
              {t('reporting_dashboard.new_backup_summary_report.report_format_instruction')}
            </Typography>
          </FormHelperText>
          <Stack direction="row" spacing={2}>
            <FormControl
              sx={{
                border: '1px solid',
                borderColor: reportFormatBorderColor(customErrorCreateBsr?.data.report_format, checked['pdf']),
                borderRadius: 1,
                px: 2.5,
                py: 1.5,
                width: 1 / 2
              }}>
              <FormControlLabel
                control={<Checkbox checked={checked.pdf} onChange={onChangeReportFormat} name="pdf" />}
                label={<PdfLabel />}
              />
            </FormControl>
            <FormControl
              sx={{
                border: '1px solid',
                borderColor: reportFormatBorderColor(customErrorCreateBsr?.data.report_format, checked['csv']),
                borderRadius: 1,
                px: 2.5,
                py: 1.5,
                width: 1 / 2
              }}>
              <FormControlLabel
                control={<Checkbox checked={checked.csv} onChange={onChangeReportFormat} name="csv" />}
                label={<CsvLabel />}
              />
            </FormControl>
          </Stack>
          {customErrorCreateBsr?.data.report_format && (
            <FormHelperText error id="component-helper-text" component="div">
              <ErrorMessage errorText={customErrorCreateBsr?.data.report_format} />
            </FormHelperText>
          )}
        </Card>

        <Card
          sx={{
            px: 4,
            py: 3,
            mb: 1,
            boxShadow: customBoxShadow
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse'
            }}>
            <Button
              variant="contained"
              sx={{ textTransform: 'none', bgcolor: 'npp.blue.50' }}
              disabled={isLoadingCreateBsr}
              onClick={onClickCreateButton}>
              {t('reporting_dashboard.new_backup_summary_report.create')}
            </Button>
          </Box>
        </Card>
      </Box>
    </ThemeProvider>
  )
}
